ion-content ion-toolbar {
  --background: translucent;
}

.container {
  --background: #136a8a; /* fallback for old browsers */
  --background: -webkit-linear-gradient(
    to right,
    #267871,
    #136a8a
  ); /* Chrome 10-25, Safari 5.1-6 */
  --background: linear-gradient(
    to right,
    #267871,
    #136a8a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.fix-width-card{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

